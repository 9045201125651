import React from 'react'
import { Typography } from '@mui/material'

const Dashboard = () => {
  return (
    <div>
       <Typography variant='h1'>Users Section in the Admin </Typography>
    </div>
  )
}

export default Dashboard
