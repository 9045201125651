import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import GradientCard from '../../special/GradientCard';
import { useDispatch, useSelector } from 'react-redux';
import { generateNewCoupon } from '../../reducer/services/DiscountCouponService';
import { useNavigate } from 'react-router';

const CouponsSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const coupon = useSelector((state) => state.coupon.coupon);
    const loading = useSelector((state) => state.coupon.loading); // Loading state to track API call
    const [open, setOpen] = useState(false); // State to control the dialog visibility
    const [showCouponModal, setShowCouponModal] = useState(false); // State to control the coupon modal visibility
    const [amount, setAmount] = useState(''); // State to store amount input
    const [couponGenerated, setCouponGenerated] = useState(false); // Track if coupon was generated

    // Function to open the dialog
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAmount('');
    };

    const handleGenerate = () => {
        const req = {
            userId: user?.id,
            discountAmount: parseFloat(amount),
        };

        // Dispatch the generate coupon action
        dispatch(generateNewCoupon(req));
        setOpen(false);
        setCouponGenerated(true); // Indicate the coupon was generated by the user
    };

    const handleCouponClose = () => {
        setShowCouponModal(false);
        setCouponGenerated(false); // Reset the state when modal is closed
    };

    // Open coupon number modal only when a new coupon is successfully generated
    useEffect(() => {
        if (couponGenerated && coupon && coupon.number) {
            setShowCouponModal(true); // Show coupon modal after successful generation
        }
    }, [coupon, couponGenerated]); // Dependency on coupon and couponGenerated flag

    const handleShowAllCoupons = () => {
        navigate('/adminPortal/coupons/allCoupons');
    };

    return (
        <>
            <Box sx={{ textAlign: 'center', p: 3 }}>
                {/* Title Section */}
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Coupon Section
                </Typography>

                {/* Cards Container using Grid */}
                <Grid container spacing={2} justifyContent="center">
                    {/* First Card with Click Event */}
                    <Grid item xs={12} sm={6} md={4} onClick={handleOpen}>
                        <GradientCard
                            text="New Coupon"
                            gradientColors={['#4e54c8', '#8f94fb']}
                            height={200}
                            textColor="#ffffff"
                        />
                    </Grid>

                    {/* Second Card */}
                    <Grid item xs={12} sm={6} md={4} onClick={handleShowAllCoupons}>
                        <GradientCard
                            text="Show all Coupons"
                            gradientColors={['#ff6a00', '#ee0979']}
                            height={200}
                            textColor="#ffffff"
                        />
                    </Grid>
                </Grid>

                {/* Dialog Popup for Amount Entry */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Enter Amount</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Amount"
                            type="number"
                            fullWidth
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleGenerate} color="secondary">
                            Generate
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Modal to Display Coupon Number */}
                <Dialog open={showCouponModal} onClose={handleCouponClose}>
                    <DialogTitle>Coupon Generated</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Your new coupon number is:
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                            {coupon?.number}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCouponClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export default CouponsSection;
